$c-primary: #F0BA00;
$c-secandary: #F0BA00;
$dots: false;
$version: 3.1;
$heading-color-overrided: #181B21;
$body-color-overrided: #181B21;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Montserrat', sans-serif;
$font-family-heading: 'Montserrat', sans-serif;

      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.formContainer {
  margin-top: 24px;
}

.formInputRow {
  position: relative;
  & > span {
    &:first-child {
      position: absolute;
      left: 0;
      top: 0;
      width: 30%;
      font-weight: bold;
      font-size: 1rem; //16
      line-height: 1.2;
      z-index: 1;
      height: 3.75rem; //60
      display: flex;
      align-items: center;
    }
  }
}
