$c-primary: #F0BA00;
$c-secandary: #F0BA00;
$dots: false;
$version: 3.1;
$heading-color-overrided: #181B21;
$body-color-overrided: #181B21;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Montserrat', sans-serif;
$font-family-heading: 'Montserrat', sans-serif;

      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  font-weight: 300;

  .content {
    margin-top: 25px;

    h3 {
      font-size: 19px;
      font-weight: 300;
    }
  }

  img {
    margin-top: 25px;
    width: 100%;
    max-width: 190px;
    border-radius: 10px;
  }
}

@media (max-height: 625px) {
  .wrapper {
    .content {
      margin-top: 10px;
    }

    img {
      max-width: 150px;
    }
  }
}

@media (max-height: 545px) {
  .wrapper {
    .content {
      margin-top: -20px;
    }

    img {
      max-width: 140px;
    }
  }
}
