$c-primary: #F0BA00;
$c-secandary: #F0BA00;
$dots: false;
$version: 3.1;
$heading-color-overrided: #181B21;
$body-color-overrided: #181B21;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Montserrat', sans-serif;
$font-family-heading: 'Montserrat', sans-serif;

      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.checkbox {
  padding-left: 25px;

  label {
    display: block;
    position: relative;
    align-items: center;
    min-height: 28px;
    padding-left: 7px;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary);
  }

  label::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    margin-left: -25px;
    border-radius: 3px;
    border: 1px solid #c6c6c6;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }

  label::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 11px;
    margin-left: -25px;
    top: 5px;
    left: 6px;
    margin-top: -3px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    background: none !important;
    transform: rotate(45deg);
    display: none;
  }

  input {
    opacity: 0;
    position: absolute;
  }

  input:checked + label::before {
    border: 1px solid var(--secondary);
  }
  input:checked + label::before,
  input:checked + label::after {
    background: var(--secondary);
    display: block;
  }
}
