$c-primary: #F0BA00;
$c-secandary: #F0BA00;
$dots: false;
$version: 3.1;
$heading-color-overrided: #181B21;
$body-color-overrided: #181B21;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Montserrat', sans-serif;
$font-family-heading: 'Montserrat', sans-serif;

      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background: var(--page-background-color);

  .body {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    top: 0;
    bottom: 4.8125rem; //45+16*2=77
    left: 0;
    right: 0;
    overflow: auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--page-padding-top) 1.5rem;
    flex: 1;
  }
  .content-force-fill-view-port {
    overflow: hidden;
  }

  .footer {
    position: absolute;
    width: 100%;
    height: 4.8125rem; //45+16*2=77
    padding: 1rem 1.5rem; //16 24
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 2;
  }

  .footerShadow {
    box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.25);
  }
  .isDark {
    background: #000 !important;
  }
}

.isDark {
  background: #000 !important;
}
